import AlertType from '~/enums/AlertType'
export const useAlertSetter = () => {
    const alertStore = useAlert();
    const setErrorAlert = (errorData: ApiError) => {
        alertStore.createAlert({
            type: AlertType.error,
            message: errorData.message,
            detail: errorData.detail,
            errorCode: errorData.status,
            errorType: errorData.type,
            validationErrors:
                errorData
                    .validation_errors,
        });
    }

    const setSuccessAlert = (message: string, isAutoDismissible: boolean = true) => {
        alertStore.createAlert({
            type: AlertType.success,
            message: message,
        });
        if(isAutoDismissible){
            setTimeout(()=>unsetAlert(),5000)
        }
    }

    const unsetAlert = () => {
        alertStore.dismissAlert();
    }

    return { setErrorAlert, setSuccessAlert, unsetAlert }
}