import AlertType from "~/enums/AlertType";
import type { Alert, AlertData, ErrorAlert, SuccessAlert } from "~/types/alert";

interface AlertState {
    alert: Alert,
    visibility: boolean,
}

export const useAlert = defineStore('alert', {
    state: (): AlertState => {
        return {
            alert: {},
            visibility: false
        }
    },
    actions: {
        createAlert(alertData: AlertData) {
            if (alertData.type == 'Success') {
                this.alert = <SuccessAlert>{
                    message: alertData.message,
                    type: AlertType.success,
                }
            } else {
                this.alert = <ErrorAlert>{
                    message: alertData.message,
                    type: AlertType.error,
                    detail: alertData.detail,
                    errorType: alertData.errorType,
                    errorCode: alertData.errorCode,
                    validationErrorsBag: alertData.validationErrors
                }
            }
            this.visibility = true;
        },
        dismissAlert() {
            this.visibility = false;
        }
    }
})