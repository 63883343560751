import Swiper from "swiper";
import type {CSSSelector} from "swiper/types";

declare module '#app' {
    interface NuxtApp {
        $swiper: Swiper;
    }
}

export default defineNuxtPlugin(async (nuxtApp) => {

        nuxtApp.provide('swiper', (selection: CSSSelector | HTMLElement, options: object) => {
            if (process.client) {
                return new Swiper(selection, options);
            }
        });
});