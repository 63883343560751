interface Position {
    longitude: string,
    latitude: string
}
export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('detectUserLocation', async () => {
        let coordinates: Array<string> = [];
        await getPosition().then((position: Position) => {
            coordinates = [position.longitude, position.latitude]
        });
        return coordinates;
    })

    function getPosition(): Promise<Position> {
        return new Promise((success, error) => {
            navigator.geolocation.getCurrentPosition((position) => {
                return {
                    longitude: position.coords.longitude,
                    latitude: position.coords.latitude
                }
            },
                (err) => {
                    return err.message;
                });
        });
    }
})