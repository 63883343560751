declare module '#app' {
    interface NuxtApp {
        $getIslamicCenterFullTimeSchoolTypeByGenders(): Promise<any>

        $getIslamicCenterQuota(): Promise<any>

        $getIslamicCenterReportTypes(): Promise<any>

        $getMyIslamicCentersByFilter(query: string): Promise<any>

        $getIslamicCentersDataFromUrl(query: string): Promise<any>

        /* Dashboard Islamic Center */
        $createIslamicCenter(createNewIslamicCenterForm: object): Promise<any>

        $getMyIslamicCenterBySlug(slug: string): Promise<any>

        $deactivateMyIslamicCenterBySlug(slug: string): Promise<any>

        $submitMyIslamicCenterForApprovalBySlug(slug: string): Promise<any>

        $updateIslamicCenterBySlug(slug: string, updateIslamicCenterForm: object): Promise<any>

        /* Dashboard Islamic Center Ends */

        /* Dashboard Islamic Center Images*/
        $getMyIslamicCenterImagesByIslamicCenterSlug(slug: string): Promise<any>

        $deleteMyIslamicCenterImageByIslamicCenterImageIndex(slug: string, index: number): Promise<any>

        /* Dashboard Islamic Center Images End*/

        /* Islamic Center Report */
        $createIslamicCenterReport(createIslamicCenterReportForm: object): Promise<any>
        /* Islamic Center Report Ends*/

        /* Islamic Center */
        $getIslamicCenterBySlug(slug: string): Promise<any>
        /* Islamic Center Ends*/

        /* Islamic Center Instant Search */
        $getInstantSearchResultsForIslamicCentersByQuery(slug: string): Promise<any>
        /* Islamic Center Instant Search Ends*/

        /* Islamic Center Prayer Timing */
        $updateIslamicCenterPrayerTimingBySlug(slug: string, prayerTimingData: object): Promise<any>
        /* Islamic Center Prayer Timing Ends*/

        /* Jummah Prayer Timings */
        $createIslamicCenterJummahPrayerTiming(slug: string, jummahPrayerTimingData: object): Promise<any>
        $updateIslamicCenterJummahPrayerTiming(slug: string, id : string, jummahPrayerTimingData: object): Promise<any>
        $deleteIslamicCenterJummahPrayerTiming(slug: string, id : string): Promise<any>
        /* Jummah Prayer Timings End*/

        /* Eid Al Adha Timings */
        $createIslamicCenterEidAlAdhaPrayerTiming(slug: string, eidAlAdhaPrayerTimingData: object): Promise<any>
        $updateIslamicCenterEidAlAdhaPrayerTiming(slug: string, id : string, eidAlAdhaPrayerTimingData: object): Promise<any>
        $deleteIslamicCenterEidAlAdhaPrayerTiming(slug: string, id : string): Promise<any>
        /* Eid Al Adha Timings End*/

        /* Eid Al Fitr Timings */
        $createIslamicCenterEidAlFitrPrayerTiming(slug: string, eidAlFitrPrayerTimingData: object): Promise<any>
        $updateIslamicCenterEidAlFitrPrayerTiming(slug: string, id : string, eidAlFitrPrayerTimingData: object): Promise<any>
        $deleteIslamicCenterEidAlFitrPrayerTiming(slug: string, id : string): Promise<any>
        /* Eid Al Fitr Timings End*/

        /* Islamic Centers Search */
        $getIslamicCentersBySearchCriteria(slug: string): Promise<any>
        /* Islamic Centers Search Ends */
    }
}


export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const {makeFetchApiCallToUrl} = useMakeFetchApiCall()

    nuxtApp.provide('getIslamicCenterFullTimeSchoolTypeByGenders', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers-full-time-school-types-by-genders`)
    })

    nuxtApp.provide('getIslamicCenterQuota', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers-quotas`)
    })

    nuxtApp.provide('getIslamicCenterReportTypes', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers-report-types`)
    })

    nuxtApp.provide('getMyIslamicCentersByFilter', async (filter: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/islamic-centers?filter=${filter}`)
    })

    nuxtApp.provide('getIslamicCentersDataFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', `${url}`)
    })

    /* Dashboard Islamic Center */
    nuxtApp.provide('createIslamicCenter', async (createNewIslamicCenterForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers`, createNewIslamicCenterForm)
    })

    nuxtApp.provide('getMyIslamicCenterBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/islamic-centers/${slug}`)
    })

    nuxtApp.provide('deactivateMyIslamicCenterBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/deactivated-islamic-centers/${slug}`)
    })

    nuxtApp.provide('submitMyIslamicCenterForApprovalBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/pending-approval`)
    })

    nuxtApp.provide('deleteMyIslamicCenterImageByIslamicCenterImageIndex', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/images/${index}`)
    })

    nuxtApp.provide('updateIslamicCenterBySlug', async (slug: string, updateIslamicCenterForm: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/islamic-centers/${slug}`, updateIslamicCenterForm)
    })
    /* Dashboard Islamic Center Ends*/

    /* Dashboard Islamic Center Images*/
    nuxtApp.provide('getMyIslamicCenterImagesByIslamicCenterSlug', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/images`)
    })
    /* Dashboard Islamic Center Images End*/

    /* Islamic Center Report */
    nuxtApp.provide('createIslamicCenterReport', async (createIslamicCenterReportForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-center-reports`, createIslamicCenterReportForm)
    })
    /* Islamic Center Report Ends*/

    /* Islamic Center */
    nuxtApp.provide('getIslamicCenterBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers/${slug}`)
    })
    /* Islamic Center End */



    /* Islamic Center Instant Search */
    nuxtApp.provide('getInstantSearchResultsForIslamicCentersByQuery', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers-instant-search?query=${queryString}`)
    })
    /* Islamic Center Instant Search Ends*/


    /* Islamic Center Prayer Timing */
    nuxtApp.provide('updateIslamicCenterPrayerTimingBySlug', async (slug: string, prayerTimingData: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-prayer-timing`,prayerTimingData)
    })
    /* Islamic Center Prayer Timing Ends*/


    /* Jummah Prayer Timings */
    nuxtApp.provide('createIslamicCenterJummahPrayerTiming', async (slug: string, jummahPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-jummah-prayer-timings`,jummahPrayerTimingData)
    })
    nuxtApp.provide('updateIslamicCenterJummahPrayerTiming', async (slug: string, id: string, jummahPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-jummah-prayer-timings/${id}`,jummahPrayerTimingData)
    })
    nuxtApp.provide('deleteIslamicCenterJummahPrayerTiming', async (slug: string, id: string) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-jummah-prayer-timings/${id}`)
    })
    /* Jummah Prayer Timings End*/

    /* Eid Al Adha Timings */
    nuxtApp.provide('createIslamicCenterEidAlAdhaPrayerTiming', async (slug: string, eidAlAdhaPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-adha-prayer-timings`,eidAlAdhaPrayerTimingData)
    })
    nuxtApp.provide('updateIslamicCenterEidAlAdhaPrayerTiming', async (slug: string, id: string, eidAlAdhaPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-adha-prayer-timings/${id}`,eidAlAdhaPrayerTimingData)
    })
    nuxtApp.provide('deleteIslamicCenterEidAlAdhaPrayerTiming', async (slug: string, id: string) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-adha-prayer-timings/${id}`)
    })
    /* Eid Al Adha Timings End*/

    /* Eid Al Fitr Timings */
    nuxtApp.provide('createIslamicCenterEidAlFitrPrayerTiming', async (slug: string, eidAlFitrPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-fitr-prayer-timings`,eidAlFitrPrayerTimingData)
    })
    nuxtApp.provide('updateIslamicCenterEidAlFitrPrayerTiming', async (slug: string, id: string, eidAlFitrPrayerTimingData: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-fitr-prayer-timings/${id}`,eidAlFitrPrayerTimingData)
    })
    nuxtApp.provide('deleteIslamicCenterEidAlFitrPrayerTiming', async (slug: string, id: string) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/islamic-centers/${slug}/islamic-center-eid-al-fitr-prayer-timings/${id}`)
    })
    /* Eid Al Fitr Timings End*/

    /* Islamic Centers Search */
    nuxtApp.provide('getIslamicCentersBySearchCriteria', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/islamic-centers-search?${queryString}`)
    })
    /* Islamic Centers Search Ends */

});
