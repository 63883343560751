export const useMakeFetchApiCall = () => {
    const authManager = useAuthManager();
    async function makeFetchApiCallToUrl(requestType: string, url: string, formData?: object | null) {
        let results = null;
        switch (requestType.toLowerCase()) {
            case 'get': {
                return await $fetch(url, {
                    method: 'get',
                    headers: {
                        accept: 'application/json',
                        content_type: 'application/json',
                        authorization: authManager.authToken.value as string
                    }
                });
                break;
            }
            case 'post': {
                return await $fetch(url, {
                    method: 'post',
                    body: formData,
                    headers: {
                        accept: 'application/json',
                        content_type: 'application/json',
                        authorization: authManager.authToken.value as string
                    }
                });
                break;
            }
            case 'put': {
                return await $fetch(url, {
                    method: 'put',
                    body: formData,
                    headers: {
                        accept: 'application/json',
                        content_type: 'application/json',
                        authorization: authManager.authToken.value as string
                    }
                });
                break;
            }
            case 'delete': {
                return await $fetch(url, {
                    method: 'delete',
                    body: formData,
                    headers: {
                        accept: 'application/json',
                        content_type: 'application/json',
                        authorization: authManager.authToken.value as string
                    }
                });
                break;
            }
            default: {
                return await $fetch(url, {
                    method: 'get',
                    headers: {
                        accept: 'application/json',
                        content_type: 'application/json',
                        authorization: authManager.authToken.value as string
                    }
                });
                break;
            }
        }
        return results;
    }

    return { makeFetchApiCallToUrl }
}