declare module '#app' {
    interface NuxtApp {
        $getFundraisingCampaignCategories(): Promise<any>

        $getFundraisingCampaignTypes(): Promise<any>

        $getFundraisingCampaignQuota(): Promise<any>

        $getFundraisingCampaignReportTypes(): Promise<any>


        $getMyFundraisingCampaignsByFilter(query: string): Promise<any>

        $getMyFundraisingCampaignsByFilterFromUrl(url: string): Promise<any>

        $getFundraisingCampaignsDataFromUrl(query: string): Promise<any>

        $createFundraisingCampaign(createNewFundraisingCampaignForm: object): Promise<any>

        $updateFundraisingCampaign(slug: string, fundraisingCampaignUpdateForm: object): Promise<any>

        $getMyFundraisingCampaignBySlug(slug: string): Promise<any>

        $completeMyFundraisingCampaignBySlug(slug: string): Promise<any>

        $submitMyFundraisingCampaignForApprovalBySlug(slug: string): Promise<any>

        $deleteMyFundraisingCampaignImageByFundraisingCampaignImageIndex(slug: string, index: number): Promise<any>

        $getMyFundraisingCampaignImagesByFundraisingCampaignSlug(slug: string): Promise<any>

        $getFundraisingCampaignDonationsDataFromUrl(url: string): Promise<any>

        $getMyFundraisingCampaignDonationsDataBySlug(slug: string): Promise<any>

        $getMyFundraisingCampaignTimelineUpdatesDataBySlug(slug: string): Promise<any>

        $getFundraisingCampaignTimelineUpdatesDataBySlugFromPageNumber(url: string, page: number): Promise<any>

        $getFundraisingCampaignTimelineUpdatesDataFromUrl(url: string): Promise<any>

        $createFundraisingCampaignTimelineUpdate(url: string, createNewFundraisingCampaignTimelineUpdateForm: object): Promise<any>

        $updateFundraisingCampaignTimelineUpdate(url: string, timelineUpdateId: string, createNewFundraisingCampaignTimelineUpdateForm: object): Promise<any>

        $deleteFundraisingCampaignTimelineUpdate(url: string, timelineUpdateId: string): Promise<any>


        /* Fundraising Campaign Report */
        $createFundraisingCampaignReport(createFundraisingCampaignReportForm: object): Promise<any>

        /* Fundraising Campaign Report Ends*/

        /* Fundraising Campaign Saving */
        $getMySavedFundraisingCampaigns(): Promise<any>
        $saveFundraisingCampaignBySlug(slug: string): Promise<any>
        $unSaveFundraisingCampaignBySlug(slug: string): Promise<any>
        /* Fundraising Campaign Saving Ends*/

        /* Fundraising Campaign Dashboard Donation */
        $getMyFundraisingCampaignGivenDonations(): Promise<any>
        /* Fundraising Campaign Dashboard Donation Ends*/

        /* Fundraising Campaign */
        $getFundraisingCampaignBySlug(slug: string): Promise<any>
        $donateToFundraisingCampaignBySlug(slug: string, fundraisingCampaignDonationForm:object): Promise<any>

        /* Fundraising Campaign Ends*/

        /* Fundraising Campaign Instant Search */
        $getInstantSearchResultsForFundraisingByQuery(slug: string): Promise<any>
        /* Fundraising Campaign Instant Search Ends*/

        /* Fundraising Campaign Search */
        $getFundraisingCampaignsBySearchCriteria(slug: string): Promise<any>
        /* Fundraising Campaign Search Ends*/

    }
}

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const {makeFetchApiCallToUrl} = useMakeFetchApiCall()

    /* Resource */
    nuxtApp.provide('getFundraisingCampaignCategories', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-campaign-categories`)
    })

    nuxtApp.provide('getFundraisingCampaignTypes', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-campaign-types`)
    })

    nuxtApp.provide('getFundraisingCampaignQuota', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-campaigns-quotas`)
    })

    nuxtApp.provide('getFundraisingCampaignReportTypes', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-campaign-report-types`)
    })

    /* Resource Ends */

    /* Fundraising Dashboard Data */
    nuxtApp.provide('getMyFundraisingCampaignsByFilter', async (filter: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns?filter=${filter}`)
    })

    nuxtApp.provide('getMyFundraisingCampaignsByFilterFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', `${url}`)
    })
    /* Fundraising Dashboard Data Ends*/

    nuxtApp.provide('getFundraisingCampaignsDataFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', url)
    })


    nuxtApp.provide('createFundraisingCampaign', async (createNewFundraisingCampaignForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/fundraising-campaigns`, createNewFundraisingCampaignForm)
    })

    nuxtApp.provide('updateFundraisingCampaign', async (slug: string, fundraisingCampaignUpdateForm: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}`, fundraisingCampaignUpdateForm)
    })

    nuxtApp.provide('getMyFundraisingCampaignBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}`)
    })

    nuxtApp.provide('completeMyFundraisingCampaignBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/completed-fundraising-campaigns/${slug}`)
    })

    nuxtApp.provide('submitMyFundraisingCampaignForApprovalBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/pending-approval`)
    })

    nuxtApp.provide('deleteMyFundraisingCampaignImageByFundraisingCampaignImageIndex', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/images/${index}`)
    })

    nuxtApp.provide('getMyFundraisingCampaignImagesByFundraisingCampaignSlug', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/images`)
    })

    /* Timeline Updates */

    nuxtApp.provide('getFundraisingCampaignTimelineUpdatesDataFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', url)
    })

    nuxtApp.provide('getFundraisingCampaignTimelineUpdatesDataBySlugFromPageNumber', async (slug: string, page: number) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/timeline-updates?page=${page}`)
    })

    nuxtApp.provide('getMyFundraisingCampaignTimelineUpdatesDataBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/timeline-updates`)
    })

    nuxtApp.provide('createFundraisingCampaignTimelineUpdate', async (slug: string, createNewFundraisingCampaignTimelineUpdateForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/timeline-updates`, createNewFundraisingCampaignTimelineUpdateForm)
    })

    nuxtApp.provide('updateFundraisingCampaignTimelineUpdate', async (slug: string, timelineUpdateId: string, updateFundraisingCampaignTimelineUpdateForm: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/timeline-updates/${timelineUpdateId}`, updateFundraisingCampaignTimelineUpdateForm)
    })

    nuxtApp.provide('deleteFundraisingCampaignTimelineUpdate', async (slug: string, timelineUpdateId: string) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/timeline-updates/${timelineUpdateId}`)
    })

    /* Timeline Updates End */

    /* Donations */
    nuxtApp.provide('getFundraisingCampaignDonationsDataFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', url)
    })

    nuxtApp.provide('getMyFundraisingCampaignDonationsDataBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-campaigns/${slug}/donations`)
    })

    /* Donations End*/


    /* Fundraising Campaign Report */

    nuxtApp.provide('createFundraisingCampaignReport', async (createFundraisingCampaignReportForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/fundraising-campaign-reports`, createFundraisingCampaignReportForm)
    })
    /* Fundraising Campaign Report Ends*/

    /* Fundraising Campaign Saving */
    nuxtApp.provide('getMySavedFundraisingCampaigns', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/saved-fundraising-campaigns`)
    })

    nuxtApp.provide('saveFundraisingCampaignBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/saved-fundraising-campaigns/${slug}`)
    })

    nuxtApp.provide('unSaveFundraisingCampaignBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/un-saved-fundraising-campaigns/${slug}`)
    })
    /* Fundraising Campaign Saving Ends*/

    /* Fundraising Campaign Dashboard Donations */
    nuxtApp.provide('getMyFundraisingCampaignGivenDonations', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/fundraising-donations`)
    })
    /* Fundraising Campaign Dashboard Donations Ends*/

    /* Fundraising Campaign  */
    nuxtApp.provide('getFundraisingCampaignBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-campaigns/${slug}`)
    })

    nuxtApp.provide('donateToFundraisingCampaignBySlug', async (slug: string, fundraisingCampaignDonationForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/fundraising-campaigns/${slug}/donations`,fundraisingCampaignDonationForm)
    })
    /* Fundraising Campaign Ends */

    /* Fundraising Campaign Instant Search */
    nuxtApp.provide('getInstantSearchResultsForFundraisingByQuery', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-instant-search?query=${queryString}`)
    })
    /* Fundraising Campaign Instant Search Ends*/

    /* Fundraising Campaign Search */
    nuxtApp.provide('getFundraisingCampaignsBySearchCriteria', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/fundraising-search?${queryString}`)
    })
    /* Fundraising Campaign Search Ends*/


})