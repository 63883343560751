declare module "#app" {
    interface NuxtApp {
        $removeScrollListener(handle:EventListener):void,
        $addScrollListener(handle:EventListener):void,
        $getScrollVerticalPositionInPixels():number,
        $hasReachedBottom():boolean
    }
}
export default defineNuxtPlugin((nuxtApp)=>{

    nuxtApp.provide("addScrollListener",(handle:EventListener)=>{
        window.addEventListener("scroll", handle);
    });

    nuxtApp.provide("removeScrollListener",(handle:EventListener)=>{
        window.removeEventListener("scroll", handle);
    });

    nuxtApp.provide("getScrollVerticalPositionInPixels",()=>{
        return window.pageYOffset;
    });

    nuxtApp.provide("hasReachedBottom",()=>{
        return Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
    });

});