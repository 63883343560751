declare module "#app" {
    interface NuxtApp {
        $getNewsAnnouncementReportTypes(): Promise<any>;
        $getNewsAnnouncementQuota(): Promise<any>;
        $getNewsAnnouncementCategories(): Promise<any>;
        $getNewsAnnouncementTags(): Promise<any>;
        $getNewsPostCategories(): Promise<any>;
        $getNewsAnnouncementFeaturedPackages(): Promise<any>;
        
        $getMyNewsAnnouncementsByFilter(filter:string): Promise<any>;
        $getNewsAnnouncementsDataFromUrl(url:string): Promise<any>;
        $submitMyNewsAnnouncementForApprovalBySlug(slug:string): Promise<any>;
        $createMyNewsAnnouncement(formData:object): Promise<any>;
        $getMyNewsAnnouncementBySlug(slug:string): Promise<any>;
        $updateNewsAnnouncementBySlug(slug:string, formData:object): Promise<any>;
        $deactivateMyNewsAnnouncementBySlug(slug:string): Promise<any>;
        
        $getMyNewsAnnouncementImagesByNewsAnnouncementSlug(slug:string): Promise<any>;
        $deleteMyNewsAnnouncementImageByNewsAnnouncementImageIndex(slug:string, index:number): Promise<any>;
        
        $pinNewsAnnouncementBySlug(slug:string): Promise<any>;
        $unPinNewsAnnouncementBySlug(slug:string): Promise<any>;
        $getMyPinnedNewsAnnouncements(): Promise<any>;
        $getMyPinnedNewsAnnouncementsDataFromUrl(url:string): Promise<any>;
        
        $getMyNewsAnnouncementOrdersByNewsAnnouncementSlug(slug:string): Promise<any>;
        $createOrderForMyNewsAnnouncementBySlug(slug:string, formData:object): Promise<any>;
        $refundOrderForMyNewsAnnouncement(slug:string, orderId:number): Promise<any>;
        
        $getNewsTickers(): Promise<any>;
        $getNewsHomeData(): Promise<any>;
        $getNewsAnnouncements(query: string|null): Promise<any>;
        $getNewsAnnouncementsFromUrl(url:string):Promise<any>;
        $getNewsAnnouncementBySlug(slug:string): Promise<any>;
        $createNewsAnnouncementReport(reportData: object): Promise<any>;

        $getNewsPosts(category:string|null): Promise<any>;
        $getNewsPostsFromUrl(url:string): Promise<any>;
        $getNewsPostBySlug(slug:string): Promise<any>;
        $getNewsPostCategoryBySlug(slug:string): Promise<any>;
       
        
        $getInstantSearchResultsForNewsByQuery(query:string): Promise<any>;
        $searchNewsAnnouncementsByCriteria(query:string): Promise<any>;
 
    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const { makeFetchApiCallToUrl } = useMakeFetchApiCall();

    /*Resources */
    nuxtApp.provide("getNewsAnnouncementReportTypes", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcement-report-types`
        );
    });

    nuxtApp.provide("getNewsAnnouncementQuota", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcements-quotas`
        );
    });

    nuxtApp.provide("getNewsAnnouncementCategories", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcement-categories`
        );
    });

    nuxtApp.provide("getNewsAnnouncementTags", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcement-tags`
        );
    });

    nuxtApp.provide("getNewsPostCategories", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-post-categories`
        );
    });

    nuxtApp.provide("getNewsAnnouncementFeaturedPackages", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcement-featured-packages`
        );
    });

    /*Resources End*/

    /* Dashboard News Announcement */
    nuxtApp.provide("getNewsAnnouncementsDataFromUrl", async (url: string) => {
        return await makeFetchApiCallToUrl("get", `${url}`);
    });

    nuxtApp.provide("getMyNewsAnnouncementsByFilter", async (filter: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/news-announcements?filter=${filter}`
        );
    });

    nuxtApp.provide(
        "submitMyNewsAnnouncementForApprovalBySlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/pending-approval`
            );
        }
    );

    nuxtApp.provide("createMyNewsAnnouncement", async (validatedForm: object) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/news-announcements`,
            validatedForm
        );
    });

    nuxtApp.provide("getMyNewsAnnouncementBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/news-announcements/${slug}`
        );
    });

    nuxtApp.provide(
        "updateNewsAnnouncementBySlug",
        async (slug: string, validatedFormData: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}`,
                validatedFormData
            );
        }
    );

    nuxtApp.provide(
        "deactivateMyNewsAnnouncementBySlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/deactivated-news-announcements/${slug}`
            );
        }
    );
    /* Dashboard News Announcement End*/

    /*New Announcement Images*/

    nuxtApp.provide(
        "getMyNewsAnnouncementImagesByNewsAnnouncementSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/images`
            );
        }
    );

    nuxtApp.provide(
        "deleteMyNewsAnnouncementImageByNewsAnnouncementImageIndex",
        async (slug: string, index: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/images/${index}`
            );
        }
    );

    /*New Announcement Images Ends*/

     /* Dashboard Pinned News Announcement*/
     nuxtApp.provide("pinNewsAnnouncementBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/pinned-news-announcements/${slug}`
        );
    });

    nuxtApp.provide("unPinNewsAnnouncementBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/un-pinned-news-announcements/${slug}`
        );
    });

    nuxtApp.provide("getMyPinnedNewsAnnouncements", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/pinned-news-announcements`
        );
    });

    nuxtApp.provide("getMyPinnedNewsAnnouncementsDataFromUrl", async (url: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });
    /* Dashboard Pinned News Announcement Ends*/

    /* News Announcement Orders */

    nuxtApp.provide(
        "getMyNewsAnnouncementOrdersByNewsAnnouncementSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/orders`
            );
        }
    );

    nuxtApp.provide(
        "refundOrderForMyNewsAnnouncement",
        async (slug: string, orderId: number) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/refunded-orders/${orderId}`
            );
        }
    );

    nuxtApp.provide(
        "createOrderForMyNewsAnnouncementBySlug",
        async (slug: string, validatedOrderFormData: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/news-announcements/${slug}/orders`,
                validatedOrderFormData
            );
        }
    );
    /* News Announcement Orders End*/

    /*News Main*/
    nuxtApp.provide("getNewsTickers", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-tickers`
        );
    });

    nuxtApp.provide("getNewsHomeData", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-home`
        );
    });

    /*News Main End*/

    /* Main News Announcement*/
    nuxtApp.provide("getNewsAnnouncements", async (queryString: string|null) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcements?query=${queryString}`
        );
    });

    nuxtApp.provide("getNewsAnnouncementsFromUrl", async (url: string|null) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide("getNewsAnnouncementBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-announcements/${slug}`
        );
    });

    nuxtApp.provide("createNewsAnnouncementReport", async (data: object) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/news-announcement-reports`,
            data
        );
    });
    /* Main News Announcement End*/

    /* Main News Post*/
    nuxtApp.provide("getNewsPosts", async (category: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-posts?category=${category}`
        );
    });

    nuxtApp.provide("getNewsPostsFromUrl", async (url:string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide("getNewsPostBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-posts/${slug}`
        );
    });

    nuxtApp.provide("getNewsPostCategoryBySlug", async (categorySlug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/news-post-categories/${categorySlug}`
        );
    });
    /* Main News Post End*/

    /* News Search */

    nuxtApp.provide(
        "getInstantSearchResultsForNewsByQuery",
        async (query: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/news-instant-search?query=${query}`
            );
        }
    );

    //Response For Below To Be Tested
    nuxtApp.provide(
        "searchNewsAnnouncementsByCriteria",
        async (queryString: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/news-search?${queryString}`
            );
        }
    );

    /* News Search End*/



});
