export const useAuthManager = () => {
  const auth = useAuth();
  const nuxtApp = useNuxtApp();
  const isLoggedIn = computed(() => {
    return auth.status.value == 'authenticated';
  });

  const authUser = computed(() => {
    if (auth.data.value) {
      return auth.data.value.user;
    }
    return null;
  });

  const authUserFullName = computed(() => {
    if (
      authUser.value &&
      authUser.value.first_name || authUser.value?.last_name
    ) {
      return `${authUser.value.first_name} ${authUser.value.last_name}`;
    }
  })

  const authUserFirstName = computed(() => {
    if (
      authUser.value &&
      authUser.value.first_name
    ) {
      return authUser.value.first_name;
    }
  });

  const authUserLastName = computed(() => {
    if (
      authUser.value &&
      authUser.value.last_name
    ) {
      return authUser.value.last_name;
    }
  });

  const authUserAvatarUrl = computed(() => {
    if (
      authUser.value &&
      authUser.value.avatar
    ) {
      return authUser.value.avatar;
    } else {
      return "/images/main/avatar-placeholder.png";
    }
  });

  const authToken = computed(() => {
    return auth.token.value;
  });

  const login = async (credentials: object, callbackRoutePath?: string) => {
    return await auth
      .signIn(credentials, {
        callbackUrl: callbackRoutePath,
      })
  }

  const register = async (registrationData: object, callbackRoutePath?: string) => {
    return await auth
      .signUp(registrationData, {
        callbackUrl: callbackRoutePath,
      })
  }

  const logout = async (callbackRoutePath?: string) => {
    return await auth
      .signOut({
        callbackUrl: callbackRoutePath,
      })
  }

  const refreshAuthUser = async (callbackRoutePath?: string) => {
    return await auth
      .getSession({
        required: true,
        callbackUrl: callbackRoutePath,
      })
  }

  const getUserProfileContact = async () => {
    const { data, error } = await useAsyncData(()=>nuxtApp.$getMyUserProfileContact());
    
    if (error.value != null) {
      useAlertSetter().setErrorAlert(error.value.data as ApiError);
      return;
    } else {
      return data.value.data;
    }
  }

  const getUserProfile = async () => {
    const { data, error } = await useAsyncData(()=>nuxtApp.$getMyUserProfile());
    
    if (error.value != null) {
      useAlertSetter().setErrorAlert(error.value.data as ApiError);
      return;
    } else {
      return data.value.data;
    }
  }

  const updateUserContactProfile = async (contactForm: object) => {
    const { data, error } = await nuxtApp.$updateMyUserProfileContact(contactForm)
    
    if (error != null) {
      useAlertSetter().setErrorAlert(error);
      return;
    } else {
      useAlertSetter().setSuccessAlert("Contact Information Updated Successfully");
      return data;
    }
  }

  const updateUserProfile = async (profileForm: object) => {
    const { data, error } = await nuxtApp.$updateMyUserProfile(profileForm)
    
    if (error != null) {
      useAlertSetter().setErrorAlert(error);
      return;
    } else {
      useAlertSetter().setSuccessAlert("Profile Updated Successfully");
      useAuthManager().refreshAuthUser();
      return data;
    }
  }


  return {
    isLoggedIn,
    authUser,
    authToken,
    authUserAvatarUrl,
    authUserFirstName,
    authUserLastName,
    authUserFullName,
    login,
    register, 
    logout, 
    refreshAuthUser, 
    updateUserContactProfile,
    updateUserProfile,
    getUserProfileContact,
    getUserProfile
  }
}