export default {
  "dashboard-classified-ad": () => import("/var/www/alahddin-fend/layouts/dashboard-classified-ad.vue").then(m => m.default || m),
  "dashboard-islamic-center": () => import("/var/www/alahddin-fend/layouts/dashboard-islamic-center.vue").then(m => m.default || m),
  "dashboard-main": () => import("/var/www/alahddin-fend/layouts/dashboard-main.vue").then(m => m.default || m),
  "dashboard-news-announcement": () => import("/var/www/alahddin-fend/layouts/dashboard-news-announcement.vue").then(m => m.default || m),
  "dashboard-simple": () => import("/var/www/alahddin-fend/layouts/dashboard-simple.vue").then(m => m.default || m),
  "dashboard-business": () => import("/var/www/alahddin-fend/layouts/dashboard_business.vue").then(m => m.default || m),
  "dashboard-fundraising-campaign": () => import("/var/www/alahddin-fend/layouts/dashboard_fundraising_campaign.vue").then(m => m.default || m),
  default: () => import("/var/www/alahddin-fend/layouts/default.vue").then(m => m.default || m),
  "email-verification": () => import("/var/www/alahddin-fend/layouts/email-verification.vue").then(m => m.default || m),
  "login-register": () => import("/var/www/alahddin-fend/layouts/login-register.vue").then(m => m.default || m),
  "main-businesses-home": () => import("/var/www/alahddin-fend/layouts/main-businesses-home.vue").then(m => m.default || m),
  "main-businesses-search": () => import("/var/www/alahddin-fend/layouts/main-businesses-search.vue").then(m => m.default || m),
  "main-businesses": () => import("/var/www/alahddin-fend/layouts/main-businesses.vue").then(m => m.default || m),
  "main-classified-home": () => import("/var/www/alahddin-fend/layouts/main-classified-home.vue").then(m => m.default || m),
  "main-classified-search": () => import("/var/www/alahddin-fend/layouts/main-classified-search.vue").then(m => m.default || m),
  "main-classified": () => import("/var/www/alahddin-fend/layouts/main-classified.vue").then(m => m.default || m),
  "main-fundraising-home": () => import("/var/www/alahddin-fend/layouts/main-fundraising-home.vue").then(m => m.default || m),
  "main-fundraising-search": () => import("/var/www/alahddin-fend/layouts/main-fundraising-search.vue").then(m => m.default || m),
  "main-fundraising": () => import("/var/www/alahddin-fend/layouts/main-fundraising.vue").then(m => m.default || m),
  "main-islamic-centers-home": () => import("/var/www/alahddin-fend/layouts/main-islamic-centers-home.vue").then(m => m.default || m),
  "main-islamic-centers-search": () => import("/var/www/alahddin-fend/layouts/main-islamic-centers-search.vue").then(m => m.default || m),
  "main-islamic-centers": () => import("/var/www/alahddin-fend/layouts/main-islamic-centers.vue").then(m => m.default || m),
  "main-news-search": () => import("/var/www/alahddin-fend/layouts/main-news-search.vue").then(m => m.default || m),
  "main-news": () => import("/var/www/alahddin-fend/layouts/main-news.vue").then(m => m.default || m),
  "main-simple": () => import("/var/www/alahddin-fend/layouts/main-simple.vue").then(m => m.default || m)
}