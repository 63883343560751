interface StripeState {
    cardElementFocusedState: boolean,
    cardElementError: string,
    cardNumberElementFocusedState: boolean,
    cardNumberElementError: string,
    cardExpiryElementFocusedState: boolean,
    cardExpiryElementError: string,
    cardCvcElementFocusedState: boolean,
    cardCvcElementError: string,
}
export const useStripe = defineStore('stripe', {
    state: (): StripeState => {
        return {
            cardElementFocusedState: false,
            cardElementError: "",
            cardNumberElementFocusedState: false,
            cardNumberElementError:"",
            cardExpiryElementFocusedState: false,
            cardExpiryElementError:"",
            cardCvcElementFocusedState: false,
            cardCvcElementError: "",
        }},

    actions: {
        setCardElementFocused() {
            this.cardElementFocusedState = true;
        },
        setCardElementUnFocused() {
            this.cardElementFocusedState = false;
        },
        setCardElementError(errorMessage : string) {
            this.cardElementError = errorMessage;
        },
        unSetCardElementError() {
            this.cardElementError = "";
        },
        /* Card Number Actions */
        setCardNumberElementFocused() {
            this.cardNumberElementFocusedState = true;
        },
        setCardNumberElementUnFocused() {
            this.cardNumberElementFocusedState = false;
        },
        setCardNumberElementError(errorMessage : string) {
            this.cardNumberElementError = errorMessage;
        },
        unSetCardNumberElementError() {
            this.cardNumberElementError = "";
        },
        /* Card Number Actions Ends */
        /* Card Expiry Actions */
        setCardExpiryElementFocused() {
            this.cardExpiryElementFocusedState = true;
        },
        setCardExpiryElementUnFocused() {
            this.cardExpiryElementFocusedState = false;
        },
        setCardExpiryElementError(errorMessage:string) {
            this.cardExpiryElementError = errorMessage;
        },
        unSetCardExpiryElementError() {
            this.cardExpiryElementError = "";
        },
        /* Card Expiry Actions Ends */
        /* Card Cvc Actions */
        setCardCvcElementFocused() {
            this.cardCvcElementFocusedState = true;
        },
        setCardCvcElementUnFocused() {
            this.cardCvcElementFocusedState = false;
        },
        setCardCvcElementError(errorMessage:string) {
            this.cardCvcElementError = errorMessage;
        },
        unSetCardCvcElementError() {
            this.cardCvcElementError = "";
        },
        /* Card Cvc Actions Ends */
    }
});