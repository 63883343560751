import { FilePond } from './../node_modules/filepond/types/index.d';
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

export default defineNuxtPlugin((nuxtApp) => {
    const filePondInstance = vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginImagePreview
    );
    nuxtApp.vueApp.component("FilePond", filePondInstance as FilePond);
})