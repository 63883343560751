declare module '#app' {
    interface NuxtApp {
        $getHomeData(): Promise<any>,
        $getClassifiedAdBySlug(slug:string): Promise<any>,
    }
}

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const { makeFetchApiCallToUrl } = useMakeFetchApiCall()

    nuxtApp.provide('getHomeData', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/`)
    })
})