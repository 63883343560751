declare module "#app" {
    interface NuxtApp {
        $getBusinessReportTypes(): Promise<any>;
        $getBusinessQuotas(filter?: string|null): Promise<any>;
        $getBusinessCategories(): Promise<any>;
        $getBusinessCouponTypes(): Promise<any>;
        $getBusinessAmenities(): Promise<any>;
        $getBusinessSubscriptionPlans(): Promise<any>;

        $getMyBusinessesByFilter(filter: string): Promise<any>;
        $getBusinessesDataFromUrl(url:string): Promise<any>;
        $submitMyBusinessForApprovalBySlug(slug: string): Promise<any>;
        $createMyBusiness(validatedForm: object): Promise<any>;
        $getMyBusinessBySlug(slug: string): Promise<any>;
        $updateBusinessNameBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessSloganBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessEstablishedYearBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessServiceAreaBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessDescriptionBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessFullAddressBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessWebsiteBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessPhoneBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessCellBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessFaxBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessEmailBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessContactUsPageUrlBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessOwnerFullNameBySlug(slug: string, data: object): Promise<any>;
        $updateBusinessAboutOwnerBySlug(slug: string, data: object): Promise<any>;
        $deactivateMyBusinessBySlug(slug: string): Promise<any>;

        $getMyBusinessImagesByBusinessSlug(slug: string): Promise<any>;
        $deleteMyBusinessImageByBusinessImageIndex(slug: string, index: number): Promise<any>;

        $getMyLikedBusinesses(): Promise<any>;
        $getMyLikedBusinessesDataFromUrl(url:string): Promise<any>;
        $likeBusinessBySlug(slug: string): Promise<any>;
        $unLikeBusinessBySlug(slug: string): Promise<any>;

        $getMyGivenBusinessReviewsAndRatings(): Promise<any>;
        $getMyGivenBusinessReviewsAndRatingsFromUrl(url:string): Promise<any>;
        $createMyGivenBusinessReviewsAndRatings(data: object): Promise<any>;
        
        $getMyBusinessReviewsByBusinessSlug(slug: string): Promise<any>;
        $getMyBusinessReviewsByBusinessSlugFromUrl(url: string): Promise<any>;
        $replyMyBusinessReviewByBusinessReviewId(businessSlug: string,reviewId: number,validatedForm: object): Promise<any>;
        $updateMyBusinessReviewReplyByBusinessReviewId(businessSlug: string,reviewId: number,validatedForm: object): Promise<any>;

        $availBusinessCouponByCouponId(slug: string,id: number,data: object): Promise<any>;
        $getMyAvailedBusinessCoupons(filter: string): Promise<any>;
        $getMyAvailedBusinessCouponsFromUrl(url:string): Promise<any>;

        $getMyBusinessCouponsByBusinessSlug(slug: string,filter: string): Promise<any>;
        $getMyBusinessCouponsByBusinessSlugFromUrl(url:string): Promise<any>;
        $getMyBusinessCouponsByCouponId(slug: string, id: number): Promise<any>;
        $createNewCouponForMyBusinessByBusinessSlug(slug: string,validatedForm: object): Promise<any>;
        $updateCouponForMyBusinessByBusinessCouponId(slug: string,couponId: number,validatedForm: object): Promise<any>;
        $deleteMyBusinessCouponByCouponId(slug: string,couponId: number): Promise<any>;
        $getBusinessCouponRedeemDetailsByEmail(slug: string,data: object): Promise<any>;
        $redeemBusinessCouponOrder(slug: string, data: object): Promise<any>;

        $getMyBusinessProductsAndServicesByBusinessSlug(slug: string): Promise<any>;
        $obtainMyBusinessProductsAndServicesFromNonSelectedCategoryByBusinessSlug(slug: string,ChosenCategories: object): Promise<any>;
       
        $getMyBusinessSalesByBusinessSlugAndFilter(slug: string,filter: string): Promise<any>;
        $getMyBusinessSalesByBusinessSlugFromUrl(url:string): Promise<any>;
        $createNewSaleForMyBusinessByBusinessSlug(slug: string,validatedForm: object): Promise<any>;
        $updateSaleForMyBusinessByBusinessSaleId(slug: string,saleId: number,validatedForm: object): Promise<any>;
        $deleteMyBusinessSaleByBusinessSaleId(slug: string,saleId: number): Promise<any>;
        
        $getMyBusinessPostsByBusinessSlug(slug: string): Promise<any>;
        $getMyBusinessPostsByBusinessSlugFromUrl(url: string): Promise<any>;
        $createNewPostForMyBusinessByBusinessSlug(slug: string,validatedForm: object): Promise<any>;
        $updatePostForMyBusinessByBusinessPostId(slug: string,postId: number,validatedForm: object): Promise<any>;
        $deleteMyBusinessPostByBusinessPostId(slug: string,postId: number): Promise<any>;

        $getMyBusinessAmenitiesByBusinessSlug(slug: string): Promise<any>;
        $getMyUnSelectedBusinessAmenitiesByBusinessSlug(slug: string): Promise<any>;
        $attachMyBusinessAmenityByBusinessAmenityId(slug: string,amenityId: number): Promise<any>;
        $detachMyBusinessAmenityByBusinessAmenityId(slug: string,amenityId: number): Promise<any>;

        $getMyBusinessAttachableProductsByBusinessSlug(slug: string): Promise<any>;
        $getMyAttachedBusinessProductsByBusinessSlug(slug: string): Promise<any>;
        $getMyUnAttachedBusinessProductsByBusinessSlug(slug: string): Promise<any>;
        $attachMyBusinessProductByBusinessProductId(slug: string,productId: number): Promise<any>;
        $detachMyBusinessProductByBusinessProductId(slug: string,productId: number): Promise<any>;

        $getMyBusinessAttachableServicesByBusinessSlug(slug: string): Promise<any>;
        $getMyAttachedBusinessServicesByBusinessSlug(slug: string): Promise<any>;
        $getMyUnAttachedBusinessServicesByBusinessSlug(slug: string): Promise<any>;
        $attachMyBusinessServiceByBusinessServiceId(slug: string,serviceId: number): Promise<any>;
        $detachMyBusinessServiceByBusinessServiceId(slug: string,serviceId: number): Promise<any>;

        $getMyBusinessCustomProductsByBusinessSlug(slug: string): Promise<any>;
        $getMyBusinessCustomProductsFromUrl(url: string): Promise<any>;
        $createNewCustomProductForMyBusinessByBusinessSlug(slug: string,validatedForm: object): Promise<any>;
        $updateCustomProductForMyBusinessByBusinessCustomProductId(slug: string,customProductId: number,validatedForm: object): Promise<any>;
        $deleteMyBusinessCustomProductByBusinessCustomProductId(slug: string,customProductId: number): Promise<any>;

        $getMyBusinessCustomServicesByBusinessSlug(slug: string): Promise<any>;
        $getMyBusinessCustomServicesFromUrl(url: string): Promise<any>;
        $createNewCustomServiceForMyBusinessByBusinessSlug(slug: string,validatedForm: object): Promise<any>;
        $updateCustomServiceForMyBusinessByBusinessCustomServiceId(slug: string,customServiceId: number,validatedForm: object): Promise<any>;
        $deleteMyBusinessCustomServiceByBusinessCustomServiceId(slug: string,customServiceId: number): Promise<any>;

        $getMyBusinessTimingByBusinessSlug(slug: string): Promise<any>;
        $updateMyBusinessTimingByBusinessSlug(slug: string,validatedForm: object): Promise<any>;

        $createSubscriptionForMyBusinessBySlug(slug: string,data: object): Promise<any>;
        $getBusinessSubscriptionDetails(slug: string): Promise<any>;
        $cancelBusinessSubscriptionBySlug(slug: string): Promise<any>;
        $resumeBusinessSubscriptionBySlug(slug: string): Promise<any>;
        $switchBusinessSubscriptionByPlanAndBySlug(businessSlug: string,planId: number,subscriptionSwitchData?: object): Promise<any>;
        $updateBusinessPaymentMethod(businessSlug: string,data: object): Promise<any>;
        $getBusinessSubscriptionPlanBySlug(slug: string): Promise<any>;
        $checkIfDowngradingBusinessBySlug(slug: string, planId: number): Promise<any>;

        $attachBusinessCategoryByMyBusinessSlug(slug: string,attachableCategory: object): Promise<any>;
        $removeBusinessCategoryByMyBusinessSlug(slug: string,removableCategory: object): Promise<any>;

        $getMyBusinessEditingPermissionsBySlug(slug: string): Promise<any>;
        $getMyBusinessQuotaBySlug(slug: string): Promise<any>;
        
        $getBusinessBySlug(slug: string): Promise<any>;
        $getBusinessesHomeData(): Promise<any>;
        $getBusinessCouponById(id: number): Promise<any>;
        $getBusinessMajorCategoryWithSubCategories(): Promise<any>;
        $getBusinessSubCategoriesByParentCategoryId(categoryId: number): Promise<any>;
        $createBusinessReport(data: object): Promise<any>;
        
        $getInstantSearchResultsForBusinessesByQuery(query: string): Promise<any>;
        $getBusinessesBySearchCriteria(queryString: string): Promise<any>;

    }
}

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const { makeFetchApiCallToUrl } = useMakeFetchApiCall();

    /* Resources */
    nuxtApp.provide("getBusinessReportTypes", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-report-types`
        );
    });

    nuxtApp.provide("getBusinessQuotas", async (filter: string|null) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/businesses-quotas?filter=${filter}`
        );
    });

    nuxtApp.provide("getBusinessCategories", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-categories`
        );
    });

    nuxtApp.provide("getBusinessCouponTypes", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-coupon-types`
        );
    });

    nuxtApp.provide("getBusinessAmenities", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-amenities`
        );
    });

    nuxtApp.provide("getBusinessSubscriptionPlans", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-subscription-plans`
        );
    });

    /* Resources End*/

    /* Dashboard Businesses */

    nuxtApp.provide("getMyBusinessesByFilter", async (filter: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses?filter=${filter}`
        );
    });

    nuxtApp.provide("getBusinessesDataFromUrl", async (url: string) => {
        return await makeFetchApiCallToUrl("get", `${url}`);
    });

    nuxtApp.provide("submitMyBusinessForApprovalBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/businesses/${slug}/pending-approval`
        );
    });

    nuxtApp.provide("createMyBusiness", async (validatedForm: object) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/businesses`,
            validatedForm
        );
    });

    nuxtApp.provide("getMyBusinessBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses/${slug}`
        );
    });

    nuxtApp.provide(
        "updateBusinessNameBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/name`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessSloganBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/slogan`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessEstablishedYearBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/established-at`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessServiceAreaBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/service-area`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessDescriptionBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/description`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessFullAddressBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/full-address`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessWebsiteBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/website`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessPhoneBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/phone`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessCellBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/cell`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessFaxBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/fax`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessEmailBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/email`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessContactUsPageUrlBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/contact-us-page-url`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessOwnerFullNameBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/owner-full-name`,
                data
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessAboutOwnerBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/about-owner`,
                data
            );
        }
    );

    nuxtApp.provide("deactivateMyBusinessBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/removed-businesses/${slug}`
        );
    });
    /* Dashboard Businesses End*/

    /* Dashboard Business Images*/
    nuxtApp.provide("getMyBusinessImagesByBusinessSlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses/${slug}/images`
        );
    });


    nuxtApp.provide(
        "deleteMyBusinessImageByBusinessImageIndex",
        async (slug: string, index: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/images/${index}`
            );
        }
    );

    /* Dashboard Business Images End*/

    /* Dashboard Liked Business*/
    nuxtApp.provide("getMyLikedBusinesses", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/liked-businesses`
        );
    });

    nuxtApp.provide("getMyLikedBusinessesDataFromUrl", async (url:string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide("likeBusinessBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/liked-businesses/${slug}`
        );
    });

    nuxtApp.provide("unLikeBusinessBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/un-liked-businesses/${slug}`
        );
    });
    /* Dashboard Liked Business End*/

    /* Dashboard Given Business Reviews */
    nuxtApp.provide("getMyGivenBusinessReviewsAndRatings", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/business-reviews`
        );
    });

    nuxtApp.provide("getMyGivenBusinessReviewsAndRatingsFromUrl", async (url:string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide(
        "createMyGivenBusinessReviewsAndRatings",
        async (data: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/business-reviews`,
                data
            );
        }
    );
    /* Dashboard Given Business Reviews End*/

    /* Dashboard My Business Reviews*/
    nuxtApp.provide(
        "getMyBusinessReviewsByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-reviews`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessReviewsByBusinessSlugFromUrl",
        async (url: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                url
            );
        }
    );

    nuxtApp.provide(
        "replyMyBusinessReviewByBusinessReviewId",
        async (businessSlug: string, reviewId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${businessSlug}/replied-business-reviews/${reviewId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updateMyBusinessReviewReplyByBusinessReviewId",
        async (businessSlug: string, reviewId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${businessSlug}/replied-business-reviews/${reviewId}`,
                validatedForm
            );
        }
    );
    /* Dashboard My Business Reviews End*/

    /* Dashboard My Availed Business Coupons*/
    nuxtApp.provide("getMyAvailedBusinessCoupons", async (filter: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/availed-business-coupons?filter=${filter}`
        );
    });

    nuxtApp.provide("getMyAvailedBusinessCouponsFromUrl", async (url:string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide(
        "availBusinessCouponByCouponId",
        async (slug: string, id: number, data: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/businesses/${slug}/availed-business-coupons/${id}`,
                data
            );
        }
    );
    /* Dashboard My Availed Business Coupons End*/

    /* Dashboard My Business Coupon*/
    nuxtApp.provide(
        "getMyBusinessCouponsByBusinessSlug",
        async (slug: string, filter: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-coupons?filter=${filter}`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessCouponsByBusinessSlugFromUrl",
        async (url:string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${url}`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessCouponsByCouponId",
        async (slug: string, id: number) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-coupons/${id}`
            );
        }
    );

    nuxtApp.provide(
        "createNewCouponForMyBusinessByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-coupons`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updateCouponForMyBusinessByBusinessCouponId",
        async (slug: string, couponId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-coupons/${couponId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "deleteMyBusinessCouponByCouponId",
        async (slug: string, couponId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-coupons/${couponId}`
            );
        }
    );

    nuxtApp.provide(
        "getBusinessCouponRedeemDetailsByEmail",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/coupon-redeem?data=${data}`
            );
        }
    );

    nuxtApp.provide(
        "redeemBusinessCouponOrder",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/coupon-redeem/`,
                data
            );
        }
    );
    /* Dashboard My Business Coupon Ends*/

    /* Dashboard My Business Products and Services*/
    nuxtApp.provide(
        "getMyBusinessProductsAndServicesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-products-and-services`
            );
        }
    );

    nuxtApp.provide(
        "obtainMyBusinessProductsAndServicesFromNonSelectedCategoryByBusinessSlug",
        async (slug: string, ChosenCategoriesIds: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/affected-products-and-services`,
                ChosenCategoriesIds
            );
        }
    );
    /* Dashboard My Business Products and Services End*/

    /*Dashboard My Business Sales*/
    nuxtApp.provide(
        "getMyBusinessSalesByBusinessSlugAndFilter",
        async (slug: string, filter: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-sales?filter=${filter}`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessSalesByBusinessSlugFromUrl",
        async (url:string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${url}`
            );
        }
    );

    nuxtApp.provide(
        "createNewSaleForMyBusinessByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-sales`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updateSaleForMyBusinessByBusinessSaleId",
        async (slug: string, saleId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-sales/${saleId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "deleteMyBusinessSaleByBusinessSaleId",
        async (slug: string, saleId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-sales/${saleId}`
            );
        }
    );
    /*Dashboard My Business Sales End*/

    /*Dashboard My Business Posts*/
    nuxtApp.provide("getMyBusinessPostsByBusinessSlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses/${slug}/business-posts`
        );
    });

    nuxtApp.provide("getMyBusinessPostsByBusinessSlugFromUrl", async (url: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${url}`
        );
    });

    nuxtApp.provide(
        "createNewPostForMyBusinessByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-posts`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updatePostForMyBusinessByBusinessPostId",
        async (slug: string, postId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-posts/${postId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "deleteMyBusinessPostByBusinessPostId",
        async (slug: string, postId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-posts/${postId}`
            );
        }
    );
    /*Dashboard My Business Posts End*/
    /*Dashboard My Business Amenities*/
    nuxtApp.provide(
        "getMyBusinessAmenitiesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-amenities`
            );
        }
    );

    nuxtApp.provide(
        "getMyUnSelectedBusinessAmenitiesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/un-selected-business-amenities`
            );
        }
    );

    nuxtApp.provide(
        "attachMyBusinessAmenityByBusinessAmenityId",
        async (slug: string, amenityId: number) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attach-business-amenities/${amenityId}`
            );
        }
    );

    nuxtApp.provide(
        "detachMyBusinessAmenityByBusinessAmenityId",
        async (slug: string, amenityId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/detach-business-amenities/${amenityId}`
            );
        }
    );
    /*Dashboard My Business Amenities End*/

    /*Dashboard My Business General Products*/
    nuxtApp.provide(
        "getMyBusinessAttachableProductsByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attachable-products`
            );
        }
    );

    nuxtApp.provide(
        "getMyAttachedBusinessProductsByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attached-products`
            );
        }
    );

    nuxtApp.provide(
        "getMyUnAttachedBusinessProductsByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/unattached-products`
            );
        }
    );

    nuxtApp.provide(
        "attachMyBusinessProductByBusinessProductId",
        async (slug: string, productId: number) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attach-business-products/${productId}`
            );
        }
    );

    nuxtApp.provide(
        "detachMyBusinessProductByBusinessProductId",
        async (slug: string, productId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/detach-business-products/${productId}`
            );
        }
    );
    /*Dashboard My Business General Products End*/

    /*Dashboard My Business General Services*/
    nuxtApp.provide(
        "getMyBusinessAttachableServicesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attachable-services`
            );
        }
    );

    nuxtApp.provide(
        "getMyAttachedBusinessServicesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attached-services`
            );
        }
    );

    nuxtApp.provide(
        "getMyUnAttachedBusinessServicesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/unattached-services`
            );
        }
    );

    nuxtApp.provide(
        "attachMyBusinessServiceByBusinessServiceId",
        async (slug: string, serviceId: number) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attach-business-services/${serviceId}`
            );
        }
    );

    nuxtApp.provide(
        "detachMyBusinessServiceByBusinessServiceId",
        async (slug: string, serviceId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/detach-business-services/${serviceId}`
            );
        }
    );
    /*Dashboard My Business General Services End*/
    
    /*Dashboard My Business Custom Product*/
    nuxtApp.provide(
        "getMyBusinessCustomProductsByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-products`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessCustomProductsFromUrl",
        async (url: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${url}`
            );
        }
    );

    nuxtApp.provide(
        "createNewCustomProductForMyBusinessByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-products`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updateCustomProductForMyBusinessByBusinessCustomProductId",
        async (slug: string, customProductId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-products/${customProductId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "deleteMyBusinessCustomProductByBusinessCustomProductId",
        async (slug: string, customProductId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-products/${customProductId}`
            );
        }
    );
    /*Dashboard My Business Custom Product Ends*/

    /*Dashboard My Business Custom Service*/
    nuxtApp.provide(
        "getMyBusinessCustomServicesByBusinessSlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-services`
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessCustomServicesFromUrl",
        async (url: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${url}`
            );
        }
    );

    nuxtApp.provide(
        "createNewCustomServiceForMyBusinessByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-services`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "updateCustomServiceForMyBusinessByBusinessCustomServiceId",
        async (slug: string, customServiceId: number, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "put",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-services/${customServiceId}`,
                validatedForm
            );
        }
    );

    nuxtApp.provide(
        "deleteMyBusinessCustomServiceByBusinessCustomServiceId",
        async (slug: string, customServiceId: number) => {
            return await makeFetchApiCallToUrl(
                "delete",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-custom-services/${customServiceId}`
            );
        }
    );
    /*Dashboard My Business Custom Service Ends*/

    /*Dashboard My Business Timing */
    nuxtApp.provide("getMyBusinessTimingByBusinessSlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses/${slug}/business-timing`
        );
    });

    nuxtApp.provide(
        "updateMyBusinessTimingByBusinessSlug",
        async (slug: string, validatedForm: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-timing`,
                validatedForm
            );
        }
    );
    /*Dashboard My Business Timing Ends */

    /*Dashboard My Business Subscription */
    nuxtApp.provide(
        "createSubscriptionForMyBusinessBySlug",
        async (slug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/business-subscriptions`,
                data
            );
        }
    );

    nuxtApp.provide("getBusinessSubscriptionDetails", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/me/businesses/${slug}/business-subscriptions`
        );
    });

    nuxtApp.provide("cancelBusinessSubscriptionBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "delete",
            `${config.public.baseApiUrl}/me/businesses/${slug}/business-subscriptions`
        );
    });

    nuxtApp.provide("resumeBusinessSubscriptionBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/businesses/${slug}/resumed-subscriptions`
        );
    });

    nuxtApp.provide(
        "switchBusinessSubscriptionByPlanAndBySlug",
        async (
            businessSlug: string,
            planId: number,
            subscriptionSwitchData?: object
        ) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${businessSlug}/switched-subscriptions/${planId}`,
                subscriptionSwitchData
            );
        }
    );

    nuxtApp.provide(
        "updateBusinessPaymentMethod",
        async (businessSlug: string, data: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${businessSlug}/payment-methods/`,
                data
            );
        }
    );

    nuxtApp.provide(
        "checkIfDowngradingBusinessBySlug",
        async (slug: string, planId: number) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/subscription-plan-downgrading-check/${planId}`
            );
        }
    );
    /*Dashboard My Business Subscription Ends*/

    /*Dashboard My Business Categories*/
    nuxtApp.provide(
        "attachBusinessCategoryByMyBusinessSlug",
        async (slug: string, attachableCategory: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/attached-business-categories`,
                attachableCategory
            );
        }
    );

    nuxtApp.provide(
        "removeBusinessCategoryByMyBusinessSlug",
        async (slug: string, removableCategory: object) => {
            return await makeFetchApiCallToUrl(
                "post",
                `${config.public.baseApiUrl}/me/businesses/${slug}/removed-business-categories`,
                removableCategory
            );
        }
    );

    
    /*Dashboard My Business Categories Ends*/

    /*Dashboard My Business Basics*/
    nuxtApp.provide(
        "getMyBusinessEditingPermissionsBySlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/edit-permissions`,
            );
        }
    );

    nuxtApp.provide(
        "getMyBusinessQuotaBySlug",
        async (slug: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/me/businesses/${slug}/quota`
            );
        }
    );
    /*Dashboard My Business Basics Ends*/

    /*Main Business*/
    nuxtApp.provide("getBusinessSubscriptionPlanBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-subscription-plans/${slug}`
        );
    });

    nuxtApp.provide("getBusinessBySlug", async (slug: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/businesses/${slug}`
        );
    });

    nuxtApp.provide("getBusinessesHomeData", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/businesses-home`
        );
    });

    nuxtApp.provide("getBusinessCouponById", async (id: number) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-coupons/${id}`
        );
    });

    nuxtApp.provide("getBusinessMajorCategoryWithSubCategories", async () => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/business-categories-tree`
        );
    });

    nuxtApp.provide(
        "getBusinessSubCategoriesByParentCategoryId",
        async (categoryId: number) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/business-categories/${categoryId}/sub-categories`
            );
        }
    );

    nuxtApp.provide("createBusinessReport", async (data: object) => {
        return await makeFetchApiCallToUrl(
            "post",
            `${config.public.baseApiUrl}/me/business-reports`,
            data
        );
    });
    /*Main Business Ends*/

    /*Business Search*/
    nuxtApp.provide(
        "getInstantSearchResultsForBusinessesByQuery",
        async (query: string) => {
            return await makeFetchApiCallToUrl(
                "get",
                `${config.public.baseApiUrl}/businesses-instant-search?query=${query}`
            );
        }
    );

    nuxtApp.provide("getBusinessesBySearchCriteria", async (queryString: string) => {
        return await makeFetchApiCallToUrl(
            "get",
            `${config.public.baseApiUrl}/businesses-search?${queryString}`
        );
    });
    /*Business Search Ends*/


});
