declare module '#app' {
    interface NuxtApp {
        /* Resources */
        $getClassifiedAdCategories(): Promise<any>

        $getClassifiedAdItemConditions(): Promise<any>

        $getClassifiedAdFeaturedPackages(): Promise<any>

        $getClassifiedAdQuota(): Promise<any>

        $getClassifiedAdReportTypes(): Promise<any>

        /* Resources Ends*/

        /* Classified Dashboard Data */
        $getMyClassifiedAdsByFilter(query: string): Promise<any>

        $getClassifiedAdsDataFromUrl(query: string): Promise<any>

        /* Classified Dashboard Data Ends */

        /* Dashboard Classified Ad */
        $createClassifiedAd(createNewClassifiedAdForm: object): Promise<any>

        $getMyClassifiedAdBySlug(slug: string): Promise<any>

        $deactivateMyClassifiedAdBySlug(slug: string): Promise<any>

        $submitMyClassifiedAdForApprovalBySlug(slug: string): Promise<any>

        $updateClassifiedAdBySlug(slug: string, updateClassifiedAdForm: object): Promise<any>

        /* Dashboard Classified Ad Ends */

        /* Dashboard Classified Ad Images*/
        $getMyClassifiedAdImagesByClassifiedAdSlug(slug: string): Promise<any>

        $deleteMyClassifiedAdImageByClassifiedAdImageIndex(slug: string, index: number): Promise<any>

        /* Dashboard Classified Ad Images End*/

        /* Dashboard Classified Ad Order*/
        $getMyClassifiedAdOrdersByClassifiedAdSlug(slug: string): Promise<any>

        $createOrderForMyClassifiedAdBySlug(slug: string, newClassifiedAdFeaturedPackageOrderForm: object): Promise<any>

        $refundOrderForMyClassifiedAd(slug: string, orderId: number): Promise<any>

        /* Dashboard Classified Ad Order Ends*/

        /* Classified Ad Report */
        $createClassifiedAdReport(createClassifiedAdReportForm: object): Promise<any>

        /* Classified Ad Report Ends*/

        /* Classified Ad Saving */
        $getMySavedClassifiedAds(): Promise<any>
        $saveClassifiedAdBySlug(slug: string): Promise<any>
        $unSaveClassifiedAdBySlug(slug: string): Promise<any>

        /* Classified Ad Saving Ends*/

        /* Classified Ad */
        $getClassifiedAdBySlug(slug: string): Promise<any>

        $getClassifiedAdContactDetailsBySlug(slug: string): Promise<any>

        /* Classified Ad Ends*/

        /* Classified Ad Instant Search */
        $getInstantSearchResultsForClassifiedByQuery(slug: string): Promise<any>

        /* Classified Ad Instant Search Ends*/

        /* Classified Search */
        $getClassifiedAdsBySearchCriteria(slug: string): Promise<any>

        /* Classified Search Ends */
    }
}

export default defineNuxtPlugin(nuxtApp => {
    const config = useRuntimeConfig();
    const {makeFetchApiCallToUrl} = useMakeFetchApiCall()

    /* Resources */
    nuxtApp.provide('getClassifiedAdCategories', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ad-categories`)
    })

    nuxtApp.provide('getClassifiedAdItemConditions', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ad-item-conditions`)
    })

    nuxtApp.provide('getClassifiedAdFeaturedPackages', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ad-featured-packages`)
    })

    nuxtApp.provide('getClassifiedAdQuota', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ads-quotas`)
    })

    nuxtApp.provide('getClassifiedAdReportTypes', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ad-report-types`)
    })
    /* Resources Ends */

    /* Classified Dashboard Data */
    nuxtApp.provide('getMyClassifiedAdsByFilter', async (filter: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/classified-ads?filter=${filter}`)
    })

    nuxtApp.provide('getClassifiedAdsDataFromUrl', async (url: string) => {
        return await makeFetchApiCallToUrl('get', `${url}`)
    })
    /* Classified Dashboard Data Ends */

    /* Dashboard Classified Ad */
    nuxtApp.provide('createClassifiedAd', async (createNewClassifiedAdForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/classified-ads`, createNewClassifiedAdForm)
    })

    nuxtApp.provide('getMyClassifiedAdBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/classified-ads/${slug}`)
    })

    nuxtApp.provide('deactivateMyClassifiedAdBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/deactivated-classified-ads/${slug}`)
    })

    nuxtApp.provide('submitMyClassifiedAdForApprovalBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/classified-ads/${slug}/pending-approval`)
    })

    nuxtApp.provide('deleteMyClassifiedAdImageByClassifiedAdImageIndex', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('delete', `${config.public.baseApiUrl}/me/classified-ads/${slug}/images/${index}`)
    })

    nuxtApp.provide('updateClassifiedAdBySlug', async (slug: string, updateClassifiedAdForm: object) => {
        return await makeFetchApiCallToUrl('put', `${config.public.baseApiUrl}/me/classified-ads/${slug}`, updateClassifiedAdForm)
    })
    /* Dashboard Classified Ad Ends*/

    /* Dashboard Classified Ad Images*/
    nuxtApp.provide('getMyClassifiedAdImagesByClassifiedAdSlug', async (slug: string, index: number) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/classified-ads/${slug}/images`)
    })
    /* Dashboard Classified Ad Images End*/

    /* Dashboard Classified Ad Order*/
    nuxtApp.provide('getMyClassifiedAdOrdersByClassifiedAdSlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/classified-ads/${slug}/orders`)
    })

    nuxtApp.provide('createOrderForMyClassifiedAdBySlug', async (slug: string, newClassifiedAdFeaturedPackageOrderForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/classified-ads/${slug}/orders`, newClassifiedAdFeaturedPackageOrderForm)
    })

    nuxtApp.provide('refundOrderForMyClassifiedAd', async (slug: string, orderId: number) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/classified-ads/${slug}/refunded-orders/${orderId}`)
    })
    /* Dashboard Classified Ad Order End*/

    /* Classified Ad Report */

    nuxtApp.provide('createClassifiedAdReport', async (createClassifiedAdReportForm: object) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/classified-ad-reports`, createClassifiedAdReportForm)
    })
    /* Classified Ad Report Ends*/

    /* Classified Ad Saving */
    nuxtApp.provide('getMySavedClassifiedAds', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/me/saved-classified-ads`)
    })

    nuxtApp.provide('saveClassifiedAdBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/saved-classified-ads/${slug}`)
    })

    nuxtApp.provide('unSaveClassifiedAdBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('post', `${config.public.baseApiUrl}/me/un-saved-classified-ads/${slug}`)
    })
    /* Classified Ad Saving Ends*/

    /* Classified Ad  */
    nuxtApp.provide('getClassifiedAdBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ads/${slug}`)
    })

    nuxtApp.provide('getClassifiedAdContactDetailsBySlug', async (slug: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-ads/${slug}/contact-details`)
    })
    /* Classified Ad Ends */

    /* Classified Ad Instant Search */
    nuxtApp.provide('getInstantSearchResultsForClassifiedByQuery', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-instant-search?query=${queryString}`)
    })
    /* Classified Ad Instant Search Ends*/

    /* Classified Ad Search */
    nuxtApp.provide('getClassifiedAdsBySearchCriteria', async (queryString: string) => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/classified-search?${queryString}`)
    })
    /* Classified Ad Search Ends*/

////////Not Set Below

    nuxtApp.provide('getClassifiedAdsForClassifiedHome', async () => {
        return await makeFetchApiCallToUrl('get', `${config.public.baseApiUrl}/api/v1/classified-ads`)
    })


/////////////////////


})